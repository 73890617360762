.table {
  .avatar-group .avatar
  {
    margin-right: 4px;
  }
  .badge i{
    margin-right: 10px;
  }

  // Forzar contenido de las tablas que se pasen a otra línea
  td {
    white-space: unset !important;
  }
}
